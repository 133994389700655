/**
 * @file
 * Custom JS behaviour for Tabella Prezzi.
 */

(function ($) {

  Drupal.behaviors.cards_carousel = {
    attach: function (context, settings) {

      $viewCards = $('.view-cards', context);

      if ($viewCards.length) {

        $carousel_three_to_one = $viewCards.find('.slick--optionset--carousel-3-in-1 > div');

        // On edge hit
        $carousel_three_to_one.on('init', function(event, slick){
          console.log('Init carousel')
          $viewCards.addClass('initialized');
        });

        $carousel_three_to_one.slick({
          "centerMode": true,
          "slidesPerRow": 3,
          "slidesToShow": 3,
          "edgeFriction": 0.5,
          "cssEase": "cubic-bezier(0.770, 0.000, 0.175, 1.000)",
          "useTransform": true,
          "easing": "easeInOutQuart",
          "responsive": [{
            "breakpoint": 1023,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
              "edgeFriction": 0.5,
              "cssEase": "cubic-bezier(0.770, 0.000, 0.175, 1.000)",
              "useTransform": true,
              "easing": "easeInOutQuart"
            }
          }, {
            "breakpoint": 640,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "centerMode": false,
              "cssEase": "cubic-bezier(0.165, 0.840, 0.440, 1.000)",
              "useTransform": true,
              "easing": "easeOutQuart"
            }
          }],
          "prevArrow": "<div class=\"slick-prev\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"12.23\" height=\"30\" viewBox=\"0 0 12.23 30\"><title>arrow-prev</title><path fill=\"none\" stroke=\"#b3b3b3\" stroke-linecap=\"round\" stroke-miterlimit=\"10\" stroke-width=\"2\" d=\"M11.23 29l-10-14 10-14\" data-name=\"Livello 1\"></path></svg></div>",
          "nextArrow": "<div class=\"slick-next\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"12.23\" height=\"30\" viewBox=\"0 0 12.23 30\"><title>arrow-next</title><path fill=\"none\" stroke=\"#b3b3b3\" stroke-linecap=\"round\" stroke-miterlimit=\"10\" stroke-width=\"2\" d=\"M1 29l10-14L1 1\" data-name=\"Livello 1\"></path></svg></div>",
          "appendArrows": $viewCards.find('.slick__arrow')
        });
      }
    }
  }

})(jQuery);