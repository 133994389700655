/**
 * @file
 * Custom JS behaviour for Section.
 */

(function ($) {

  Drupal.behaviors.section = {
    attach: function (context, settings) {

      if ($('body').hasClass('node-type-sezione')) {
        // Qui inserire il codice per le section.

        var $page_wrapper = $('#page-wrapper', context);

        $page_wrapper.append('<div id="overlay-mappa"></div>');

        var $overlay_mappa = $('#overlay-mappa', context);
        var $map_icon = $('.teaser-list-map-icon', context);
        var tl = null;

        $map_icon.click(function (e) {
          
          e.preventDefault();

          tl = new TimelineMax({ 
            onStart: function() {
              $map_icon.css({ pointerEvents: 'none'});
              $('body').css({ overflow: 'hidden', paddingRight: '20px' });
              $overlay_mappa.addClass('visible');
            },
            onComplete: function () {
              $(document).on('click', '.overlay-close, .field-name-field-mappa', function (e) {

                e.preventDefault();

                if (e.target != $(this)[0]) {
                  return ;
                }

                $('.overlay-close').remove();

                if (tl) {
                  tl.timeScale(1.5);
                  tl.reverse();
                }
              });
            },
            onReverseComplete: function() {
              $map_icon.css({ pointerEvents: 'auto'});
              $('body').css({ overflow: 'auto', paddingRight: '0px' });
              $overlay_mappa.removeClass('visible');
            }
          });

          var $map = $(this).parent().siblings('.field-name-field-mappa');

          $overlay_mappa.html($map.wrap('<div/>').parent().html());
          $map.unwrap();

          var $oMap = $overlay_mappa.find('.field-name-field-mappa');
          var $oMapItem = $oMap.find('.field-item');


          $oMapItem.prepend('<a href="#" class="overlay-close">' + Drupal.t('CLOSE') + '</a>');
          
          if($(window).width() >= 100){
            tl.fromTo($oMap, .25, { opacity: 0 }, { opacity: 1 })
              .fromTo($oMapItem, .5, { width: 0 }, { width: '80vw' })
              .fromTo($oMapItem, .5, { height: '1px' }, { height: '80vh' })
              .fromTo($oMapItem.find('img'), .5, { opacity: 0 }, { opacity: 1 })
              .fromTo($('.overlay-close'), .35, { opacity: 0 }, { opacity: 1 });
          } else if($(window).width() >= 800){
            tl.fromTo($oMap, .25, { opacity: 0 }, { opacity: 1 })
              .fromTo($oMapItem, .5, { width: 0 }, { width: '50vw' })
              .fromTo($oMapItem, .5, { height: '1px' }, { height: '70vh' })
              .fromTo($oMapItem.find('img'), .5, { opacity: 0 }, { opacity: 1 })
              .fromTo($('.overlay-close'), .35, { opacity: 0 }, { opacity: 1 });
          } 
        
        });
      }
    }
  }

})(jQuery);

