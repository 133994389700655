(function ($) {

  Drupal.behaviors.formuladiamond = {
    attach: function (context, settings) {

        var $cards = $('.view-servizi-prodotto-carousel .group-service-row-wrapper');


        if (matchMedia) {
          var mq = window.matchMedia("(min-width: 640px)");
          mq.addListener(WidthChange);
          WidthChange(mq);
        }

        function WidthChange(mq) {
          if (mq.matches) {
            fixMaxHeight();
          } else {
            resetMaxHeight();
          }
        }

        function fixMaxHeight() {
          $cards.each(function() {
            var $img = $(this).find('.group-service-row-image img');
            var $text = $(this).find('.group-service-row-text');
            $text.css('max-height', $img.width() - 30);
          });
        }

        function resetMaxHeight() {
          $cards.each(function() {
            var $text = $(this).find('.group-service-row-text');
            $text.css('max-height', '');
          });
        }
    }
  }

})(jQuery);




