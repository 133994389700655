/**
 * @file
 * Custom JS behaviour for Photos gallery on excursion and day by day.
 */

(function ($) {

  Drupal.behaviors.photos_gallery = {
    attach: function (context, settings) {

      var $photos_gallery_container = $('.field-collection-item-field-coll-localita-vacanze.view-mode-vi_teaser, .field-collection-item-field-programmi.view-mode-vi_teaser', context);

      if ($photos_gallery_container.length) {
        var $icons_gallery = $photos_gallery_container.find('.icon-gallery');
        $icons_gallery.off('click').on('click', function (e) {
          var $magnific_pop = $(this).closest('.field-item').find('.mfp-gallery-image.magnific_popup-processed > a');
          if ($magnific_pop.length) {
            $magnific_pop.click();
          }
        });
      }
    }
  }

})(jQuery);

