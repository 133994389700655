/**
 * @file
 * Custom JS behaviour for Catalogue Cover.
 */

(function ($) {

  Drupal.behaviors.generic_animations = {
    attach: function (context, settings) {

      var controller = new ScrollMagic.Controller();

      $.each($('.animate-block-intro', context), function (indexInArray, valueOfElement) {

        var item           = this;
        var $childrenItems = $(".animate-block-intro > div > div > div");
        var tl             = new TimelineMax({paused: true});

        tl.to(item, .5, {
          opacity: 1,
          yPercent: 0,
          scale: 1,
          ease: Power4.easeOut
        })
          .staggerTo($childrenItems, .75, {
            opacity: 1,
            y: 0,
            ease: Power4.easeOut
          }, .15);

        TweenMax.set(item, {opacity: 0, yPercent: 5, scale: .9});
        TweenMax.set($childrenItems, {opacity: 0, y: -20});

        new ScrollMagic.Scene({
          triggerElement: this,
          triggerHook: 0.95
        })
          .on("enter", function (event) {
            tl.play();
          })
          .on("leave", function (event) {
            tl.reverse();
          })
          .addTo(controller);
      });

      $.each($('.animate-block-generic', context), function (indexInArray, valueOfElement) {

        var item        = this;
        var $blockTitle = $(this).find("h2");
        var tl          = new TimelineMax({paused: true});

        tl.to(item, 1, {opacity: 1, ease: Power4.easeOut})
          .to($blockTitle, 1, {x: 0, opacity: 1, ease: Power4.easeOut}, "-=1");

        TweenMax.set(item, {opacity: 0});
        TweenMax.set($blockTitle, {x: -20, opacity: .5});

        new ScrollMagic.Scene({
          triggerElement: this,
          triggerHook: 0.80
        })
          .on("enter", function (event) {
            tl.play();
          })
          .on("leave", function (event) {
            tl.reverse();
          })
          .addTo(controller);
      });

      $.each($('.animate-row-item', context), function (indexInArray, valueOfElement) {

        var item = this;
        var tl   = new TimelineMax({paused: true});

        tl.to(item, 1, {x: 0, opacity: 1, ease: Power4.easeOut});

        TweenMax.set(item, {x: -15, opacity: 0});

        new ScrollMagic.Scene({
          triggerElement: this,
          triggerHook: 0.80
        })
          .on("enter", function (event) {
            tl.play();
          })
          .on("leave", function (event) {
            tl.reverse();
          })
          .addTo(controller);
      });

      // Day by day
      $.each($('.animate-daybyday-item', context), function (indexInArray, valueOfElement) {

        var item           = this;
        var $daybydayImage = $(this).find('.field-name-field-programma-immagine').find('img');
        var $imageCaption  = $(this).find('.group-day-itinerary-image').find('.photo-didascalie');
        var $daybydayDesc  = $(this).find('.field-name-field-programma-desc');
        var $daybydayTitle = $(this).find('.field-name-field-programma-titolo');
        var tl             = new TimelineMax({paused: true});

        TweenMax.set($daybydayTitle, {opacity: 0, xPercent: -20});
        TweenMax.set($daybydayDesc, {opacity: 0, xPercent: -10});
        TweenMax.set($daybydayImage, {opacity: 0, xPercent: -20});
        TweenMax.set($imageCaption, {opacity: 0});

        tl.to($daybydayTitle, .75, {
          opacity: 1,
          xPercent: 0,
          ease: Back.easeOut
        })
        tl.to($daybydayDesc, 1.5, {
          opacity: 1,
          xPercent: 0,
          ease: Back.easeOut
        }, "-=0.75")
        tl.to($daybydayImage, 2, {
          opacity: 1,
          xPercent: 0,
          ease: Power4.easeOut
        }, "-=1.25")
        tl.to($imageCaption, 1, {opacity: 1, ease: Power4.easeOut});

        new ScrollMagic.Scene({
          triggerElement: this,
          triggerHook: 0.70
        })
          .on("enter", function (event) {
            tl.play();
          })
          .on("leave", function (event) {
            tl.reverse();
          })
          .addTo(controller);
      });

      $.each($('.animate-grid-item:nth-child(odd)', context), function (indexInArray, valueOfElement) {

        var item       = this;
        var $gridImage = $(this).find(".field-type-image").find("img");
        var $gridText  = $(this).find(".field-type-text");
        var $gridTitle = $(this).find(".field-name-title");
        var $gridLink  = $(this).find(".field-name-node-link");

        var tl = new TimelineMax({paused: true});

        tl.to(item, .5, {x: 0, opacity: 1, ease: Power4.easeOut})
          .to($gridImage, 2, {
            scale: 1,
            opacity: 1,
            ease: Power4.easeOut
          }, "-=.5")
          .to($gridText, 1.5, {x: 0, opacity: 1, ease: Power4.easeOut}, "-=1.5")
          .to($gridTitle, 1.5, {
            x: 0,
            opacity: 1,
            ease: Power4.easeOut
          }, "-=1.4")
          .to($gridLink, 1.5, {
            scale: 1,
            opacity: 1,
            ease: Power4.easeOut
          }, "-=1.3");

        TweenMax.set(item, {x: -15, opacity: 0});
        TweenMax.set($gridImage, {scale: 1.1, opacity: .4});
        TweenMax.set($gridText, {x: -10, opacity: 0});
        TweenMax.set($gridTitle, {x: -10, opacity: 0});
        TweenMax.set($gridLink, {scale: 1.1, opacity: 0});

        new ScrollMagic.Scene({
          triggerElement: this,
          triggerHook: 0.80
        })
          .on("enter", function (event) {
            tl.play();
          })
          .on("leave", function (event) {
            tl.reverse();
          })
          .addTo(controller);
      });

      $.each($('.animate-grid-item:nth-child(even)', context), function (indexInArray, valueOfElement) {

        var item       = this;
        var $gridImage = $(this).find(".field-type-image").find("img");
        var $gridText  = $(this).find(".field-type-text");
        var $gridTitle = $(this).find(".field-name-title");
        var $gridLink  = $(this).find(".field-name-node-link");

        var tl = new TimelineMax({paused: true});

        tl.to(item, .5, {x: 0, opacity: 1, ease: Power4.easeOut}, "+=0.5")
          .to($gridImage, 2, {
            scale: 1,
            opacity: 1,
            ease: Power4.easeOut
          }, "-=.5")
          .to($gridText, 1.5, {x: 0, opacity: 1, ease: Power4.easeOut}, "-=1.5")
          .to($gridTitle, 1.5, {
            x: 0,
            opacity: 1,
            ease: Power4.easeOut
          }, "-=1.4")
          .to($gridLink, 1.5, {
            scale: 1,
            opacity: 1,
            ease: Power4.easeOut
          }, "-=1.3");

        TweenMax.set(item, {x: 15, opacity: 0});
        TweenMax.set($gridImage, {scale: 1.1, opacity: .4});
        TweenMax.set($gridText, {x: -10, opacity: 0});
        TweenMax.set($gridTitle, {x: -10, opacity: 0});
        TweenMax.set($gridLink, {scale: 1.1, opacity: 0});

        new ScrollMagic.Scene({
          triggerElement: this,
          triggerHook: 0.80,
          reverse: true
        })
          .on("enter", function (event) {
            tl.play();
          })
          .on("leave", function (event) {
            tl.reverse();
          })
          .addTo(controller);
      });

      $.each($('.animate-column', context), function (indexInArray, valueOfElement) {

        var item        = this;
        var $columnItem = $(this).find(".animate-column-item");

        var tl = new TimelineMax({paused: true});

        TweenMax.set($columnItem, {y: 30, opacity: 0});

        tl.staggerTo($columnItem, .5, {
          y: 0,
          opacity: 1,
          ease: Back.easeOut
        }, .15);

        new ScrollMagic.Scene({
          triggerElement: this,
          triggerHook: 0.85,
          reverse: true
        })
          .on("enter", function (event) {
            tl.play();
          })
          .on("leave", function (event) {
            tl.reverse();
          })
          .addTo(controller);
      });


      // POPUP CONTATTI
      var pc_tag          = "#block-contact-agency";
      var $popup_contatti = $(pc_tag, context);

      if ($popup_contatti.length) {
        var trigger_pc      = "body.page-node";
        var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        var diff = h + 400;
        var offset = $(trigger_pc).height() - diff;
        var popupController = new ScrollMagic.Controller({
          globalSceneOptions: {
            triggerHook: "onLeave"
          }
        });
        new ScrollMagic.Scene({
          triggerElement: trigger_pc,
          triggerHook: 0,
          offset: offset
        })
          .setClassToggle(pc_tag, "visible")
          .addTo(popupController);

        var $pc         = $('#block-contact-agency', context);
        var $pc_intro   = $('.intro', context);
        var $pc_close   = $('.popup-contatti-button-close', context);
        var $pc_open    = $('.popup-contatti-button-open', context);
        var $pc_cta     = $('.cta-wrapper', context);
        var pc_timeline = new TimelineMax({delay: 0.3, paused: true});

        TweenMax.set($pc, {y: 0});

        pc_timeline.to($pc_cta, .5, {
          xPercent: -10,
          opacity: 0,
          ease: Power4.easeInOut
        })
          .to($pc_intro, .5, {
            xPercent: -10,
            opacity: 0,
            ease: Power4.easeInOut
          }, "-=.35")
          .to($pc, .5, {xPercent: -100, ease: Expo.easeOut}, "-=.25");

        $pc_close.click(function (e) {
          e.preventDefault();
          pc_timeline.play();
          $pc.addClass('collapsed');
        });

        $pc_open.click(function (e) {
          e.preventDefault();
          pc_timeline.reverse();
          $pc.removeClass('collapsed');
        });

        $(document).on('scroll', function (e) {
          var currentScroll = document.documentElement.scrollTop;
          var footerTop = $('#footer').offset().top;
          var marginBottom = (currentScroll + h) - footerTop;
          if (marginBottom >= 0) {
            $popup_contatti.css({
              'margin-bottom': marginBottom + 'px'
            });
          }
          else {
            $popup_contatti.css({
              'margin-bottom': 0
            });
          }
        });
      }
    }
  };

})(jQuery);
