/**
 * @file
 * Custom JS behaviour for Tour Page.
 */

(function ($) {

  Drupal.behaviors.tour = {
    attach: function (context, settings) {

      if ($('body.node-type-tour').length) {
        // Qui inserire il codice per la pagina tour.

        var controller = new ScrollMagic.Controller({
          globalSceneOptions: {
            triggerHook: "onLeave"
          }
        });

        Tour(controller);
      }

      function Tour(controller) {

        var tl = new TimelineMax({delay: 0.5});

        var $dayBydayGroup = $('.group-day-by-day', context),
            $dayBydayItem = $dayBydayGroup.find('.field-name-field-programmi > .field-items > .field-item');

        var offsetY = -74;

        // TOUR CORRELATI
        var tc_tag = ".field-name-field-field-serv-prodotto-ref";
        var $tour_correlati = $(tc_tag, context);
        var trigger_tc = $tour_correlati.next('div')[0];

        if ($tour_correlati.length) {
          new ScrollMagic.Scene({
            triggerElement: trigger_tc,
            triggerHook: 1,
            offset: -500
          })
          .setClassToggle(tc_tag, "visible")
          .addTo(controller);

          var $tc = $('.field-name-field-field-serv-prodotto-ref', context);
          var $tc_intro = $('.tour-correlati-intro', context);
          var $tc_close = $('.tour-correlati-intro-close', context);
          var $tc_open = $('.tour-correlati-intro-open', context);
          var $tc_image = $('.destination-vacation-types-row-image', context);
          var $tc_desc_inner = $('.group-teaser-description-inner', context);
          var tc_timeline = new TimelineMax({ paused: true });

          $tc_image.width($tc_image.height() * Drupal.settings.awdp_tour_hotel.ratio_tc);
          $tc_image.css('left', -$tc_image.outerWidth());

          TweenMax.set($tc, { y: 0 });
          TweenMax.set($tc_image, { x: 0 });

          tc_timeline.to($tc_image, .3, { x: $tc_image.outerWidth(), ease: Power4.easeOut })
                     .to($tc, .5, { y: $tc_desc_inner.outerHeight(), ease: Power4.easeOut });

          $tc_close.click(function (e) {
            e.preventDefault();
            tc_timeline.play();
            $tc_intro.addClass('collapsed');
          });

          $tc_open.click(function (e) {
            e.preventDefault();
            tc_timeline.reverse();
            $tc_intro.removeClass('collapsed');
          });
        }

        // PIN MAP ITINERARIO
        $(window).load(function () {

          var $map_itinerary = $('#page-wrapper', context).not('.catalogo-des').find('.field-name-field-mappa');
          // var $map_itinerary_in_custom_catalogue = $('.node-tour', context).not('.view-mode-vi_des_full').find('.field-name-field-mappa');
          var map_height = $map_itinerary.find('canvas').length ? $map_itinerary.find('canvas').height() : $map_itinerary.find('img').height();
          var pin_duration = $('.field-name-field-programmi').height() - map_height;

          if ($map_itinerary.length) {
            if($(window).width() >= 641){
              new ScrollMagic.Scene({
                triggerElement: '.group-day-by-day h3',
                duration: pin_duration,
                triggerHook: 0,
                reverse: true
              })
              .on("enter", function (event) {
                if ($dayBydayGroup.find('.loader-container').length) {
                  $dayBydayGroup.find('.loader-container').addClass('loader-fixed');
                }
              })
              .on("leave", function (event) {
                if ($dayBydayGroup.find('.loader-container').length) {
                  $dayBydayGroup.find('.loader-container').removeClass('loader-fixed');
                }
              })
              .setPin(".field-name-field-mappa .field-item")
              .addTo(controller);
            }
          }
        });

        $dayBydayItem.each(function(){
          var tlDayByDay = new TimelineMax({});

          //tlDayByDay.set($(".field-name-field-programma-titolo"), {opacity: 0, xPercent: -50});

          new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.5,
            offset: offsetY,
            reverse: true
          })
          .on("enter", function (event) {
            tlDayByDay.to($(this).find('.field-name-field-programma-titolo'), 1, { xPercent: 0, opacity: 1, ease: Back.easeOut});
          })
          .addTo(controller);
        });
      }
    }
  }

})(jQuery);

