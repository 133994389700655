/**
 * @file
 * Custom JS behaviour for Catalogue Cover.
 */

(function ($) {

  var ytPlayer;
  var playing = false;
  var playerSetup = 0;
  var volume = {
    value: 0
  };
  var errors = 0;
  window.onYouTubeIframeAPIReady = function () {
    var ytSettings = Drupal.settings.ytPlayer;
    ytSettings.events = {
      onReady: onPlayerReady,
      onStateChange: onStateChange,
      onError: onPlayerError
    };
    ytPlayer = new YT.Player('iframe_video', ytSettings);
  };

  function onPlayerError() {
    if (errors < 1) {
      errors++;
      var iframe = ytPlayer.getIframe();
      var new_iframe = document.createElement('div');
      new_iframe.id = 'iframe_video';
      iframe.parentNode.replaceChild(new_iframe, iframe);
      ytPlayer = new YT.Player('iframe_video', Drupal.settings.ytPlayer);
    }
  }

  function onPlayerReady(event) {
    // Hide loader.
    TweenMax.to($('.hero-video-loader'), 0.1, {autoAlpha: 0, display: 'none'});
    $('.group-hero').find('.loading').removeClass('loading');
    ytPlayer.playVideo();
  }

  function playerSetVolume(vPlayer) {
    vPlayer.setVolume(volume.value * 100);
    if (volume.value === 0) {
      vPlayer.mute();
    }
    else {
      vPlayer.unMute();
    }
  }

  function onStateChange(event) {
    var $groupHero = $('.group-hero');
    var $fieldTitle = $groupHero.find('.group-hero-text-inner');
    var $overlay = $groupHero.find('.hero-video-overlay');
    var $iconPlay = $('.icon-play');
    var $heroAnchors = $groupHero.find('.hero-anchors');

    if (playing) {

      $iconPlay.removeClass('off');

      TweenMax.to($overlay, 1, {
        backgroundColor: 'rgba(0, 0, 0, 0)'
      });
      TweenMax.to($fieldTitle, .25, {
        scaleX: 1.1,
        scaleY: 1.1,
        ease: Power4.easeIn,
        opacity: 0
      });
      TweenMax.to($heroAnchors, .25, {
        opacity: 0,
        yPercent: 50,
        delay: 0.5
      });
      TweenLite.to(volume, 2, {
        value: 1,
        ease: Power4.easeOut,
        delay: .5,
        onUpdate: playerSetVolume,
        onUpdateParams: [ytPlayer]
      });
    }
    else {
      $iconPlay.addClass('off');

      TweenMax.to($overlay, 1, {
        backgroundColor: 'rgba(53,46,52,.7)',
      });
      TweenMax.to($fieldTitle, .5, {
        scaleX: 1,
        scaleY: 1,
        ease: Power4.easeOut,
        delay: .5,
        opacity: 1
      });
      TweenMax.to($heroAnchors, .5, {
        opacity: 1,
        yPercent: 0,
        delay: 1
      });
      if (volume.value > 0) {
        TweenLite.to(volume, 2, {
          value: 0,
          ease: Power4.easeOut,
          onUpdate: playerSetVolume,
          onUpdateParams: [ytPlayer]
        });
      }
    }
  }


  Drupal.behaviors.hero_video = {
    attach: function (context, settings) {

      var $video_hero = $('video#hero_video', context);
      var $video_hero_small = $('#iframe_video', context);
      var $header = $('#header');
      var $groupHero = $('.group-hero');
      var player = $video_hero;
      var controller = new ScrollMagic.Controller();


      if (playerSetup != 0) {
        return;
      }
      if (Object.prototype.hasOwnProperty.call(Drupal.settings, 'ytPlayer') &&
        Object.prototype.hasOwnProperty.call(Drupal.settings.ytPlayer, 'display') &&
        Drupal.settings.ytPlayer.display == 'desktop') {

        var $fieldVideo = $groupHero.find('.field-name-field-video-url');
        var $playButton = $groupHero.find('.hero-video-play');
        var $resetButton = $groupHero.find('.hero-video-reset');

        $groupHero.addClass('loading');
        $('.loader-inner').loaders();

        var tl = new TimelineMax({paused: true});

        tl.to($playButton, .5, {
          css: {bottom: "90px"},
          ease: Power4.easeOut
        })
          .to($playButton, .25, {
            x: -30,
            ease: Power0.easeOut
          }, "+=.15")
          .to($resetButton, .5, {
            css: {bottom: "90px"},
            ease: Power4.easeOut
          }, "-=1.5")
          .to($resetButton, .25, {
            x: 30,
            className: "+=active",
            ease: Power0.easeOut
          });

        var tl_hero = new TimelineMax({paused: true});

        tl_hero.to($groupHero, .25, {
          css: {marginTop: "0px"},
          ease: Power4.easeOut
        })
          .to($fieldVideo, .5, {
            className: "+=open"
          }, 0);

        var playClick = function (e) {
          e.preventDefault();

          playing = !playing;
          if (playing) {
            ytPlayer.unMute();
            tl.play();
            tl_hero.play();
          }
          else {
            tl.reverse();
            tl_hero.reverse();
          }
          onStateChange(e);
        };

        $playButton.on('click', playClick);

        $resetButton.on('click', function (e) {
          e.preventDefault();

          ytPlayer.seekTo(0);
        });

        new ScrollMagic.Scene({
          triggerElement: ".group-hero",
          triggerHook: "onLeave",
          duration: $groupHero.height() + 'px',
          offset: -$header.height()
        })
          .on("leave", function (event) {
            playing = 0;
            tl.reverse();
            tl_hero.reverse();

            initResetAnimationHero();
          })
          .addTo(controller);

        player.off('timeupdate').on('timeupdate', function () {
          var duration = this.duration;
          var currentTime = this.currentTime;

          if ((currentTime >= (0.99 * duration)) && playing) {
            playing = !playing;
            tl.reverse();
            tl_hero.reverse();
            initResetAnimationHero();
          }
        });

        new ScrollMagic.Scene({
          triggerElement: "#page",
          offset: 0,
          duration: "600",
          triggerHook: 0
        })
          .setTween($video_hero, {yPercent: 30, ease: Linear.easeNone})
          .addTo(controller);
      }
      else if ($video_hero_small.length) {
        // player = new Vimeo.Player($video_hero_small[0]);
        new ScrollMagic.Scene({
          triggerElement: ".group-hero",
          triggerHook: "onLeave",
          duration: $groupHero.height() + 'px',
          offset: -$header.height()
        })
          .on("leave", function (event) {
            ytPlayer.pauseVideo();
          })
          .addTo(controller);
      }

      function initResetAnimationHero() {
      }

      playerSetup = 1;
    }
  }

})(jQuery);
