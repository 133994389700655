/**
 * @file
 * Custom JS behaviour for viaggidea_theme theme.
 */

// Dimensions.
var big_width = 1441,
  full_width = 1180,
  medium_width = 980,
  small = 500,
  small_start = 460,
  small_end = 640;

(function ($) {
  // global code
  Drupal.behaviors.main = {
    attach: function (context, settings) {

      var $tooltip = $('.tooltip-hover', context);
      var $anchor_visualizza = $('.anchor-visualizza', context);
      var $anchor_scroll = $('.anchor-scroll', context);
      var $anchor_banner = $('.anchor-banner', context);
      var $header = $('#header');
      var $header_fixed = $('#header-fixed');
      var $block_agency = $('#block-awdp-trade-agency-informations', context);
      var $agencyToolbar = $block_agency.find('.agency-informations-toolbar');
      var agencyToolbarHeight = $agencyToolbar.length ? $agencyToolbar.outerHeight() : 0;
      var headerHeight = $header.length ? $header.height() + agencyToolbarHeight : agencyToolbarHeight;
      var anchor_hash = window.location.hash.replace('#', '') || '';

      $anchor_scroll.click(function (e) {
        e.preventDefault();

        var $banner = $(this).closest('.anchor-banner');
        var $target = null;

        if ($(this).data('target') != undefined && $(this).data('target') != "") {
          var target = $(this).data('target');
          $target = $(target);
        }
        else {
          var $container = $banner.length ? $(this).closest('.anchor-banner').parent() : $(this).parent();
          $target = $container.nextAll('div:not(.field-name-field-field-serv-prodotto-ref)').eq(0);
        }

        if ($target != null && $target.length) {

          var scrollY = $target.offset().top - $header_fixed.height();

          TweenLite.to(window, 1, {scrollTo: {y: scrollY, autoKill: false}, ease: Power4.easeOut});
        }
      });

      var controller = new ScrollMagic.Controller();

      $(window).load(function () {

        if ($header_fixed.length) {
          // $header_fixed.css({ top: agencyToolbarHeight });

          if ($(window).scrollTop() > headerHeight) {
            TweenMax.to($header_fixed, .5, {autoAlpha: 1, display: 'block'});
          }
          else {
            TweenMax.to($header_fixed, .5, {autoAlpha: 0, display: 'none'});
          }
        }

        var $anchor_hash = $(document).find('*[id="' + anchor_hash + '-hash-anchor"]');

        // only try to scroll to offset if target has been set in location hash
        if (anchor_hash != '' && $anchor_hash.length) {

          var anchor_hash_Y = $anchor_hash.offset().top - $header_fixed.height();

          TweenMax.to(window, 1, {scrollTo: {y: anchor_hash_Y, autoKill: false}, ease: Power4.easeOut});
        }

        if ($anchor_banner.length) {

          $.each($anchor_banner, function (indexInArray, valueOfElement) {

            var tl = new TimelineMax({});
            var $container = $(this).closest('.field-group-fieldset').length ? $(this).closest('.field-group-fieldset') : $(this).closest('.field-group-div');
            var $map_itinerary = $('#page-wrapper', context).not('.catalogo-des').find('.field-name-field-mappa', context);
            var map_height = 0;
            if ($map_itinerary.length) {
              map_height = $map_itinerary.find('canvas').length ? $map_itinerary.find('canvas').height() : $map_itinerary.find('img').height();
            }
            var pin_duration = $('.field-name-field-programmi').height() - map_height;

            new ScrollMagic.Scene({
              triggerElement: ".group-day-by-day",
              triggerHook: 0,
              duration: pin_duration,
              reverse: true
            })
              .on("enter", function (event) {
                tl.to($anchor_banner, 0.5, {yPercent: -100, ease: Power4.easeOut});
              })
              .on("leave", function (event) {
                tl.to($anchor_banner, 0.5, {yPercent: 100, ease: Power4.easeOut});
              })
              .addTo(controller);
          });
        }

        if (Modernizr.flexbox && Modernizr.flexwrap) {
          // Modern Flexbox with `flex-wrap` is supported
        } else {
          // flexibility(document.documentElement);
        }


      });

      $anchor_visualizza.click(function (e) {
        e.preventDefault();

        var target = $(this).data('target');
        var $target = $(target);

        if ($(target).css('height') == '0px') {
          TweenMax.set($target, {height: 'auto', opacity: 1});
          TweenMax.from($target, .5, {height: 0, opacity: 0, ease: Power4.easeOut, force3D: true});
          TweenMax.to(window, .5, {scrollTo: {y: $(target).offset().top - $header_fixed.height(), autoKill: false}});
        }
        else {
          TweenMax.to($target, .5, {
            height: 0,
            opacity: 0,
            ease: Power4.easeOut,
            force3D: true
          });
        }
      });

      $tooltip.hover(function (e) {
        e.preventDefault();

        $(this).next('p, .focus-cliente-tooltip').toggleClass('tooltip-visible');
      });

      $tooltip.click(function (e) {
        e.preventDefault();
      });

      var $heroImage = $('.group-hero').find('.field-name-field-image-anteprima, .field-name-field-immagini').find("img");
      if ($heroImage.length) {
        new ScrollMagic.Scene({
          triggerElement: "#page",
          offset: 0,
          duration: "600",
          triggerHook: 0
        })
          .setTween($heroImage, {yPercent: 30, ease: Linear.easeNone})
          .addTo(controller);
      }

      if ($('.field-name-field-scheda-destinazione').length) {
        var tlHero = new TimelineMax({});

        var $heroTitle = $('.field-name-field-scheda-destinazione').find('.group-hero').find('.field-name-title').find('h2');
        var $heroImageSection = $('.field-name-field-scheda-destinazione').find('.group-hero').find('.field-name-field-immagini').find('img');

        new ScrollMagic.Scene({})
          .on("enter", function (event) {
            tlHero.from($heroImageSection, 4, {scale: 1.1, opacity: 0, ease: Power4.easeOut})
              .from($heroTitle, 0.5, {scale: .9, opacity: 0, ease: Power4.easeOut}, "-=1.5");
          })
          .on("leave", function (event) {
          })
          .addTo(controller);
      }

      $(window).scroll(function (e) {
        var windowScrollTop = $(window).scrollTop();

        if ($header_fixed.length) {
          if (windowScrollTop > headerHeight) {
            TweenMax.to($header_fixed, .5, {autoAlpha: 1, display: 'block'});
          }
          else {
            TweenMax.to($header_fixed, .5, {autoAlpha: 0, display: 'none'});
          }
        }
      });
    }
  }

})(jQuery);
