var world_map = null;

(function ($) {

  Drupal.behaviors.main_menu_map = {
    attach: function (context, settings) {

      var $main_menu = $('.main-menu');
      var $main_menu_map = $main_menu.find('.main-menu-map');

      if ($main_menu_map.length) {

        var base_path    = settings.baseUrl + '/' + settings.themePath;
        var mapplic_path = base_path + '/dist/js/plugins/world-maps';
        var map_json     = mapplic_path + '/json/map-' + $main_menu.find('.main-menu-map').data('catalogue') + '.json';

        $(document).ready(function () {
          // Load Json for the catalogue.
          $.ajax({
            dataType: "json",
            url: map_json,
            success: function (data) {

              // Add path to svg map url.
              data.levels["0"].map = mapplic_path + data.levels["0"].map;

              $.each(data.levels["0"].locations, function (index, location) {
                // Cambia ogni link con l'url di base del sito secondo il dominio.
                data.levels["0"].locations[index].link = settings.baseUrl + location.link;
              });

              var map_title_height = $main_menu.find('.main-menu-map-title').length > 0 ? $main_menu.find('.main-menu-map-title').height() : 0;

              world_map = $('#mapplic').mapplic({
                selector: '[id^=landmark] > *',
                source: data,
                height: $(window).height() - map_title_height - 54,
                sidebar: false,
                search: false,
                minimap: false,
                zoombuttons: false,
                deeplinking: false,
                fullscreen: false,
                hovertip: true,
                developer: false,
                maxscale: 1,
                mapfill: false,
                mousewheel: false,
                zoom: false
              });

              var $mainMenu      = $('.main-menu', context);
              var $mainMenuRows  = $mainMenu.find('.main-menu-row');

              $mainMenuRows.hover(function (e) {
                // In.
                var $row           = $(this);
                var location       = $row.data('location');

                if ($main_menu_map) {
                  var $location  = $main_menu_map.find('.mapplic-pin[data-location="' + location + '"]');
                  var $locations = $main_menu_map.find('.mapplic-pin');
                  $locations.not('.mapplic-active').addClass('inactive');
                  $location.removeClass('inactive');
                }
              }, function (e) {
                // Out.
                var $row     = $(this);
                var location = $row.data('location');

                if ($main_menu_map) {
                  var $locations = $main_menu_map.find('.mapplic-pin');
                  if (!$main_menu_map.find('.mapplic-pin.mapplic-active').length) {
                    $locations.removeClass('inactive');
                  }
                  else {
                    $locations.not('.mapplic-active').addClass('inactive');
                  }
                }
              });

              if ($main_menu_map) {
                var $pins = $main_menu_map.find('.mapplic-pin');

                $main_menu_map.find('.mapplic-pin').hover(function (e) {
                  var $pin     = $(this);
                  var location = $pin.data('location');
                  var $row     = $mainMenu.find('.main-menu-row[data-location="' + location + '"]');

                  $mainMenuRows.removeClass('pinned');
                  $row.addClass('pinned');
                  $pins.not('.mapplic-active').addClass('inactive');
                  $pin.removeClass('inactive');

                }, function (e) {
                  var $pin     = $(this);
                  var location = $pin.data('location');
                  var $row     = $mainMenu.find('.main-menu-row[data-location="' + location + '"]');

                  $mainMenuRows.removeClass('pinned');
                  if (!$main_menu_map.find('.mapplic-pin.mapplic-active').length) {
                    $pins.removeClass('inactive');
                  }
                  else {
                    $pins.not('.mapplic-active').addClass('inactive');
                  }
                });

                $main_menu_map.find('.mapplic-pin').click(function (e) {
                  var $pin     = $(this);
                  var location = $pin.data('location');
                  var $row     = $mainMenu.find('.main-menu-row[data-location="' + location + '"]');

                  $pins.addClass('inactive').removeClass('mapplic-active');
                  $pin.removeClass('inactive');
                  $row[0].scrollIntoView();
                  $row.click();
                });
              }
            }
          });
        });
      }
    }
  }

})(jQuery);
