/**
 * @file
 * Custom JS behaviour for Hotel Page.
 */

(function ($) {

  Drupal.behaviors.hotel = {
    attach: function (context, settings) {

      if ($('body.node-type-hotel').length) {
        // Qui inserire il codice per la pagina tour.

        var controller = new ScrollMagic.Controller({
          globalSceneOptions: {
            triggerHook: "onLeave"
          }
        });

        $sistemazioni            = $('.view-servizi-struttura.view-display-id-block');
        $servizi_struttura       = $sistemazioni.find('.field-collection-item-field-servizi-struttura');
        $servizi_struttura_items = $servizi_struttura.find('.sistemazione-item');

        $servizi_struttura_items.find('.sistemazione-title-container').click(function (e) {
          e.preventDefault();
          $sistemazione_parent = $(this).closest('.sistemazione-item');
          $sistemazione_parent.find('.sistemazione-info-container').slideToggle();
          $sistemazione_parent.find('.sistemazione-item-expand').toggleClass('alter');
          $sistemazione_parent.find('.sistemazione-item-collapse').toggleClass('alter');
        });

        Hotel(controller);
      }

      function Hotel(controller) {
        // TOUR CORRELATI
        var tc_tag = ".field-name-field-field-serv-prodotto-ref";
        var $tour_correlati = $(tc_tag, context);
        var trigger_tc = $tour_correlati.next('div')[0];

        if ($tour_correlati.length) {
          new ScrollMagic.Scene({
            triggerElement: trigger_tc,
            triggerHook: 1,
            offset: -500
          })
          .setClassToggle(tc_tag, "visible")
          .addTo(controller);

          var $tc = $('.field-name-field-field-serv-prodotto-ref', context);
          var $tc_intro = $('.tour-correlati-intro', context);
          var $tc_close = $('.tour-correlati-intro-close', context);
          var $tc_open = $('.tour-correlati-intro-open', context);
          var $tc_image = $('.destination-vacation-types-row-image', context);
          var $tc_desc_inner = $('.group-teaser-description-inner', context);
          var tc_timeline = new TimelineMax({ paused: true });

          $tc_image.width($tc_image.height() * Drupal.settings.awdp_tour_hotel.ratio_tc);
          $tc_image.css('left', -$tc_image.outerWidth());

          TweenMax.set($tc, { y: 0 });
          TweenMax.set($tc_image, { x: 0 });

          tc_timeline.to($tc_image, .3, { x: $tc_image.outerWidth(), ease: Power4.easeOut })
                     .to($tc, .5, { y: $tc_desc_inner.outerHeight(), ease: Power4.easeOut });

          $tc_close.click(function (e) {
            e.preventDefault();
            tc_timeline.play();
            $tc_intro.addClass('collapsed');
          });

          $tc_open.click(function (e) {
            e.preventDefault();
            tc_timeline.reverse();
            $tc_intro.removeClass('collapsed');
          });
        }
      }
    }
  }

})(jQuery);

