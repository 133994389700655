/**
 * @file
 * Custom JS behaviour for Servizi Prodotto Cover.
 */

(function ($) {

  Drupal.behaviors.servizi_prodotto = {
    attach: function (context, settings) {

      var service_groups_array = [];

      if ($('.view-servizi-prodotto.viaggidea-only').length) {

        var $view = $('.view-servizi-prodotto', context);
        var $view_group = $view.find('.view-group');
        
        var controller = new ScrollMagic.Controller({
          globalSceneOptions: {
            triggerHook: "onLeave"
          }
        });

        $view_group.each(function (index) {
          
          var group = $(this);
          var $group_carousel = group.find('.carousel_service_product');
          var $view_rows = group.find('.views-row');
          var $sp_carousel_link = $view_rows.find('.sp_carousel_link');
          var $sp_carousel_link_start = $sp_carousel_link.eq(0);
          
          var modalTimeLine = new TimelineMax({ paused: true });
          
          var nid = $sp_carousel_link_start.data('nid');
          var cid = $sp_carousel_link_start.data('cid');

          $sp_carousel_link_start.addClass('is-active');
          loadFromServiceProducts($sp_carousel_link_start, modalTimeLine, nid, cid);

          $sp_carousel_link.click(function (e) {

            e.preventDefault();

            var _this = $(this);
            var $service_text = _this.find('.group-service-row-text');
            var $service_title = $service_text.find('.field-name-title');
            var nid = _this.data('nid');
            var cid = _this.data('cid');

            if (_this.hasClass('is-active')) {
              return ;
            }

            if ($sp_carousel_link.hasClass('is-active')) {
              modalTimeLine.eventCallback("onReverseComplete", function () {

                modalTimeLine = new TimelineMax({ paused: true });

                $sp_carousel_link.removeClass('is-active');

                _this.addClass('is-active');
                loadFromServiceProducts(_this, modalTimeLine, nid, cid);
              });

              modalTimeLine.timeScale(1.5);
              modalTimeLine.reverse();
            }
            else {
              _this.addClass('is-active');
              loadFromServiceProducts(_this, modalTimeLine, nid, cid);
            }

          });
        });        
      }

      function loadFromServiceProducts(cell, modalTimeLine, nid, cid) {
            
        var group = cell.closest('.view-group'); 
        var $bg_field = cell.find('.field-name-field-category-icon').find('.field-item').find('div');        

        $.ajax({
          url: Drupal.settings.baseUrl + '/views/ajax',
          type: 'post',
          data: {
            view_name: 'servizi_prodotto_carousel',
            view_display_id: Drupal.settings.awdp_content.sp_carousel_block, //your display id
            view_args: nid + "/" + cid , // your views arguments
          },
          dataType: 'json',
          success: function (response) {
            if (response[1] !== undefined) {
              
              var $carousel_container = group.find('.carousel_service_product');

              $carousel_container.html(response[1].data);

              var $sp_carousel = $carousel_container.find('.view-servizi-prodotto-carousel > .view-content');
              var slick_settings = {
                accessibility: true,
                adaptiveHeight: false,
                arrows: true,
                autoplay: false,
                autoplaySpeed: 3000,
                centerMode: false,
                centerPadding: "50px",
                cssEase: "ease",
                dots: true,
                dotsClass: "slick-dots",
                draggable: true,
                easing: "swing",
                edgeFriction: 0.35,
                fade: false,
                focusOnSelect: false,
                infinite: true,
                initialSlide: 0,
                lazyLoad: "ondemand",
                mousewheel: false,
                nextArrow: "<div class='slick-next'></div>",
                pauseOnDotsHover: false,
                pauseOnHover: true,
                prevArrow: "<div class='slick-prev'></div>",
                rows: 1,
                rtl: false,
                slide: "",
                slidesPerRow: 1,
                slidesToScroll: 1,
                slidesToShow: 1,
                speed: 500,
                swipe: true,
                swipeToSlide: false,
                touchMove: true,
                touchThreshold: 5,
                useCSS: true,
                useTransform: false,
                variableWidth: false,
                vertical: false,
                verticalSwiping: false,
                waitForAnimate: true,
              };
              
              slick_settings.prevArrow = '<div class="slick-prev"></div>';
              slick_settings.nextArrow = '<div class="slick-next"></div>';
              slick_settings.dots = true;
              // slick_settings.adaptiveHeight = true;

              $sp_carousel.on('init', function (event, slick) {

                $sp_carousel.addClass('slick slick--optionset--carousel-full');
                $carousel_container.css('background-image', $bg_field.css('background-image'));

                //modalTimeLine.from($carousel_container, 1, { opacity: 0, ease: Power4.easeInOut, immediateRender: false }, 0);

                modalTimeLine.play();
              });

              $sp_carousel.slick(slick_settings);
            }
          }
        });
      }
    }
  }

})(jQuery);
