/**
 * @file
 * Custom JS behaviour for Servizi Prodotto Cover.
 */

(function ($) {

  Drupal.behaviors.carousel_two_to_one = {
    attach: function (context, settings) {

      var $carousel_two_to_one = $('.slick--optionset--carousel-2-in-1').not('.slick--view--videos');

      if ($carousel_two_to_one.length) {

        $(window).load(function () {
          $carousel_two_to_one.each(function () {

            var $slick_container = $(this);
            var $slick_two_to_one = $(this).find('.slick-slider');
            var slick_options = $slick_two_to_one.data('slick');
            var $slides = $slick_two_to_one.find('.slide');

            if ($slides.length > 1 && $slides.length <= slick_options.slidesToShow) {
              $slides.each( function(){
                $(this).addClass('slick-active');
              });
            }

            slick_options.prevArrow = '<div class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="12.23" height="30" viewBox="0 0 12.23 30"><title>arrow-prev</title><path fill="none" stroke="#b3b3b3" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M11.23 29l-10-14 10-14" data-name="Livello 1"></path></svg></div>';
            slick_options.nextArrow = '<div class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="12.23" height="30" viewBox="0 0 12.23 30"><title>arrow-next</title><path fill="none" stroke="#b3b3b3" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M1 29l10-14L1 1" data-name="Livello 1"></path></svg></div>';
            slick_options.appendArrows = $(this).find('.slick__arrow');


            var slick_open_options = $.extend(true, {}, slick_options);

            slick_open_options.centerMode = 'false';
            slick_open_options.slidesPerRow = 1;
            slick_open_options.slidesToShow = 1;
            slick_open_options.responsive = [{
              "breakpoint": 1023,
              "settings": {
                "centerMode": false,
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "edgeFriction": 0.5,
                "cssEase": "cubic-bezier(0.770, 0.000, 0.175, 1.000)",
                "useTransform": true,
                "easing": "easeInOutQuart"
              }
            }, {
              "breakpoint": 640,
              "settings": {
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "centerMode": false,
                "cssEase": "cubic-bezier(0.165, 0.840, 0.440, 1.000)",
                "useTransform": true,
                "easing": "easeOutQuart"
              }
            }];

            $(document).on('click', '.view-servizi-prodotto-carousel.view-display-id-block_5 .slick-prev, .view-cards .slick-prev', function (e) {
              $slick_two_to_one.slick('slickPrev');
            });

            $(document).on('click', '.view-servizi-prodotto-carousel.view-display-id-block_5 .slick-next, .view-cards .slick-next', function (e) {
              $slick_two_to_one.slick('slickNext');
            });

            var slide_index = 0;

            var isSliding = false;

            $slick_two_to_one.on('beforeChange', function() {
                isSliding = true;
            });

            $slick_two_to_one.on('afterChange', function() {
                isSliding = false;
            });

            $slick_two_to_one.find('.slick-slide').click(function (e) {

              if (isSliding) {
                return;
              }

              slide_index = $(this).data('slick-index');

              if ($slick_two_to_one.hasClass('slick-initialized')) {
                $slick_two_to_one.slick('unslick');
                $slick_container.find('.slick__arrow').html('');
              }
              if (!$slick_two_to_one.hasClass('opened')) {
                $slick_two_to_one.slick(slick_open_options);

                $slick_two_to_one.on('afterChange', function (event, slick, currentSlide, nextSlide) {
                  var $currentSlide = $(slick.$slides[currentSlide]);
                  $(this).find('.slick-list').height($currentSlide.height());
                  isSliding = false;
                });
              } else {
                $slick_two_to_one.slick(slick_options);
                $slides = $slick_two_to_one.find('.slide');

                if ($slides.length > 1 && $slides.length <= slick_options.slidesToShow) {
                  $slides.each( function(){
                    $(this).addClass('slick-active');
                  });
                }
              }

              $slick_two_to_one.toggleClass('opened');
              $slick_two_to_one.slick('slickGoTo', slide_index, true);
            });
          });
        });
      }
    }
  }

})(jQuery);