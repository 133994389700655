/**
 * @file
 * Custom JS behaviour for Catalogue Cover.
 */

(function ($) {

  Drupal.behaviors.avancatalogo = {
    attach: function (context, settings) {

      if ($('body[class*=avancatalogo]').length) {
        // Qui inserire il codice per la pagina avancatalogo.

        var controller = new ScrollMagic.Controller({
          // globalSceneOptions: {
          //   triggerHook: "onEnter"
          // }
        });

        AvantCatalogue(controller);
      }

      function AvantCatalogue(controller) {

        if ($(window).width() > medium_width) {
          $.each($('.rubric', context), function (indexInArray, valueOfElement) {

            var $rubricImage = $(this).find('.rubric-image').find('img');

            TweenMax.set($rubricImage, {yPercent: 20});

            new ScrollMagic.Scene({
              triggerElement: this,
              offset: 0,
              duration: "200%",
              triggerHook: 1
            })
            .setTween($rubricImage, {yPercent: -75, ease: Linear.easeNone})
            .addTo(controller);
          });

          $.each($('.rubric.right', context), function (indexInArray, valueOfElement) {

            var $rubricContent = $(this).find('.rubric-content');

            TweenMax.set($rubricContent, {yPercent: 40});

            new ScrollMagic.Scene({
              triggerElement: this,
              offset: 0,
              duration: "200%",
              triggerHook: 1
            })
            .setTween($rubricContent, {yPercent: -50, ease: Linear.easeNone})
            .addTo(controller);
          });

          $.each($('.rubric.left', context), function (indexInArray, valueOfElement) {

            var $rubricContent = $(this).find('.rubric-content');

            TweenMax.set($rubricContent, {yPercent: 40});

            new ScrollMagic.Scene({
              triggerElement: this,
              offset: 0,
              duration: "200%",
              triggerHook: 1
            })
            .setTween($rubricContent, {yPercent: -50, ease: Linear.easeNone})
            .addTo(controller);
          });
        }

        $.each($('.rubric', context), function (indexInArray, valueOfElement) {

          var tlRubricTitle = new TimelineMax({ paused: true });

          var $rubricTitleOne = $(this).find('h2');
          var $rubricTitleTwo = $(this).find('h3');
          var $rubricTitleThree = $(this).find('h4');
          var $rubricIcon = $(this).find('.icon');
          var $rubricP = $(this).find('p');
          var $rubricUl = $(this).find('ul');
          var $rubricI = $(this).find('.icons').find('li');

          TweenMax.set($rubricTitleOne, {opacity: 0, yPercent: -15});
          TweenMax.set($rubricTitleTwo, {opacity: 0, yPercent: -15});
          TweenMax.set($rubricTitleThree, {opacity: 0, yPercent: -15});
          TweenMax.set($rubricIcon, {opacity: 0, yPercent: -15});
          TweenMax.set($rubricP, {opacity: 0, yPercent: 15});
          TweenMax.set($rubricUl, {opacity: 0, yPercent: 15});
          TweenMax.set($rubricI, {opacity: 0, xPercent: -15});

          tlRubricTitle.to($rubricTitleOne, 0.5, { opacity: 1, yPercent: 0, ease: Power4.easeOut })
                        .to($rubricTitleTwo, 0.5, { opacity: 1, yPercent: 0, ease: Power4.easeOut }, "0")
                        .to($rubricTitleThree, 0.5, { opacity: 1, yPercent: 0, ease: Power4.easeOut }, "0")
                        .to($rubricIcon, 0.5, { opacity: 1, yPercent: 0, ease: Power4.easeOut }, "0")
                        .to($rubricP, 0.5, { opacity: 1, yPercent: 0, ease: Power4.easeOut }, "0")
                        .to($rubricUl, 0.5, { opacity: 1, yPercent: 0, ease: Power4.easeOut }, "0")
                        .staggerTo($rubricI, 0.75, { opacity: 1, xPercent: 0, ease: Power4.easeOut }, .3);

          new ScrollMagic.Scene({
            triggerElement: this,
            offset: 0,
            triggerHook: 0.75
          })
          .on("enter", function (event) {
            tlRubricTitle.play();
          })
          .on("leave", function (event) {
            tlRubricTitle.reverse();
          })
          .addTo(controller);

        });

        $.each($('.view-columns', context), function (indexInArray, valueOfElement) {

          var tlRubricColumns = new TimelineMax({ paused: true });
          var $viewsRow = $(this).find('.views-row');

          TweenMax.set($viewsRow, {opacity: 0, y: 20});

          tlRubricColumns.staggerTo($viewsRow, 0.5, { opacity: 1, y: 0, ease: Power4.easeOut }, .25);;

          new ScrollMagic.Scene({
            triggerElement: this,
            offset: 0,
            triggerHook: 0.75
          })
          .on("enter", function (event) {
            tlRubricColumns.play();
          })
          .on("leave", function (event) {
            tlRubricColumns.reverse();
          })
          .addTo(controller);

        });

      }

      $(document).ready(function() {
        $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
          disableOn: 700,
          type: 'iframe',
          mainClass: 'mfp-fade',
          removalDelay: 160,
          preloader: false,
      
          fixedContentPos: false
        });
      });
    }
  }

})(jQuery);

