/**
 * @file
 * Custom JS behaviour for Catalogue Cover.
 */

(function ($) {

  Drupal.behaviors.paravento = {
    attach: function (context, settings) {

      if ($('.destinations-paravento').length) {
        Paravento();
      }

      function Paravento() {
        var paraventoViewContent = $('.destinations-paravento').find('.view-content');
        var paraventoItems = paraventoViewContent.find('>.views-row').length;
        console.log(paraventoItems);
        paraventoViewContent.addClass('grid-' + paraventoItems + '-items');
      }
    }
  }

})(jQuery);

