/**
 * @file
 * Custom JS behaviour for Tabella Prezzi.
 */

(function ($) {

  Drupal.behaviors.tab_prezzi = {
    attach: function (context, settings) {

      var $fieldTablePrezzi = $('.field-name-field-tab-prezzi', context);

      if ($fieldTablePrezzi.length) {
        var $fieldTablePrezziContent = $fieldTablePrezzi.find('.field-name-field-tab-prezzi-content > .field-items > .field-item');
        var $tablesPrezzi = $fieldTablePrezziContent.find('table');

        $tablesPrezzi.sort(function(a, b) {
          var wa = a.id.split("F")[1];
          var wb = b.id.split("F")[1];
          return parseInt(wa) - parseInt(wb);
        }).each(function() {
          var elem = $(this);
          elem.remove();
          $fieldTablePrezziContent.append(elem);
        });
      }
    }
  }

})(jQuery);